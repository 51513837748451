import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import "../scss/WhatIDoBarAbout.scss";
import { Link } from "gatsby";

const WhatIDoBar = () => {
  const data = useStaticQuery(graphql`
    query MyQueryWhatIDoAbout {
      allContentfulValuePropositionBar(filter: { id: { eq: "6ee1d145-e3ee-513d-add5-f0dabf3017b6" } }) {
        edges {
          node {
            bodyText
            bodyText2
            bodyText3
            title
            title2
            title3
            image3 {
              altText
              image {
                file {
                  url
                }
              }
            }
            image2 {
              altText
              image {
                file {
                  url
                }
              }
            }
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            id
            outsideTitle
          }
        }
      }
    }
  `);

  const {
    title,
    title2,
    title3,
    bodyText,
    bodyText2,
    bodyText3,
    image,
    image2,
    image3,
  } = data.allContentfulValuePropositionBar.edges[0].node;
  const [name3, description3] = bodyText3.split("Digital Transformation");
  const [name2, description2] = bodyText2.split("Reports");
  // const [name1, description1] = bodyText.split("Luxury");

  return (
    <div className="contentFeatureWhatIDoAbout">
      <div className="wbarAbout">
        <div className="outsideTitleAbout">My Services</div>
        <div className="witemsAbout">
          <div className="witem1About">
          <Link to="/luxury-customer-sentimentanalysis">
            <img className="wimage1About" src={image.image.file.url} alt={image.altText} />
          </Link>
          </div>
          <div className="witem2About">
            <Link to="/luxury-circulareconomy">
              <img className="wimage2About" src={image2.image.file.url} alt={image2.altText} />
            </Link>
          </div>
          <div className="witem3About">
            <Link to="/luxury-keynotespeaker">
              <img className="wimage3About" src={image3.image.file.url} alt={image3.altText} />
            </Link>
          </div>
        </div>
        <div className="learnMoreAbout">Learn More</div>
      </div>
    </div>
  );
};

export default WhatIDoBar;