import React, { useEffect } from "react";
import "../scss/AboutUsPageFlexBox.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { StaticImage } from "gatsby-plugin-image"

export default function AboutUsPageFlexBox() {
  const data = useStaticQuery(graphql`
    query MyQuery27 {
      allContentfulFlexBoxStyle(filter: { title: { eq: "Values, Mission, Purpose & Principles" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            callToAction
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            subTitle
            title
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  if (data.allContentfulFlexBoxStyle.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  return (
    <div className="contentFeature9">
      <div className="boardTextContainer1 fade-in">
        <div className={"textContainer9"}>
          <p className={"title9"}>
            {data.allContentfulFlexBoxStyle.edges[0].node.title}
          </p>
          <p className={"categoryName9"}>
          Nú Heritage
          </p>
          <style>{`
              .bodyText9 br {
                display: block;
                margin: 1em 0;
                content: "";
              }
            `}</style>
          <p className={"bodyText9"}>
            {documentToReactComponents(
              JSON.parse(
                data.allContentfulFlexBoxStyle.edges[0].node.bodyText.raw
              ),
              options
            )}
          </p>
          <div className={"callToAction9"}>
            <Link
              to="/about/"
              style={{ textDecoration: "underline", color: "white" }}
            >
              <span style={{ fontFamily: "AriaText-SemiBold" }}>
                {data.allContentfulFlexBoxStyle.edges[0].node.callToAction}
              </span>
            </Link>
          </div>
          <style>{`
            callToAction1:visited {
              color: white;
            }
          `}</style>
        </div>
        <div className={"displayBoardContainer9"}>
          <div className="displayBoard9"></div>
        </div>
      </div>
      <div className={"displayBarContainer9"}>
        <div data-aos="fade-up" className="displayBar1" />
      </div>
      <div className={"imageContainer9"}>
        <img
          // data-aos="fade-up"
          className={"bigImage"}
          alt={data.allContentfulFlexBoxStyle.edges[0].node.image.altText}
          key={data.allContentfulFlexBoxStyle.edges[0].node.image.image.file.url}
          src={data.allContentfulFlexBoxStyle.edges[0].node.image.image.file.url}
        />
      </div>
    </div>
  );
}
