import React, { useEffect } from "react";
import "../scss/AboutUsPicture5.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { StaticImage } from "gatsby-plugin-image"

export default function AboutUsPicture5() {
  const data = useStaticQuery(graphql`
    query MyQuery405 {
      allContentfulImageBlock(filter: { name: { eq: "Luxury Sneakerhead" } }) {
        edges {
          node {
            image {
              image {
                file {
                  url
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000, offset: -60 });
  }, []);

  if (data.allContentfulImageBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  return (
    <div className="contentFeature5">
      <div data-aos="fade-up" className="boardTextContainer5 fade-in">
        <div className={"displayBoardContainer5"}>
          <div className="displayBoard5"></div>
        </div>
      </div>

      <div className={"textContainer5"}>
        <p className={"title5"}>
          {data.allContentfulImageBlock.edges[0].node.name}
        </p>
      </div>

      <div className={"imageContainer5"}>
        {/* <img
          data-aos="fade-up"
          className={"bisd"}
          alt={""}
          key={""}
          src={data.allContentfulImageBlock.edges[0].node.image.image.file.url}
        /> */}
        <StaticImage data-aos="fade-up" className={"bigImage"} src="https://images.ctfassets.net/x5jqyuhgi4rx/6HYnmbWYnNek6Kp7MZSGrP/a8c01256d913dd9eb59ad12675fe0ec9/luxurysneakerheadfk_1.webp" layout="fullWidth" alt=""/>
      </div>
    </div>
  );
}
