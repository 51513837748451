import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby";
import "../scss/partner.scss";
import Aos from "aos"
import "aos/dist/aos.css"

export default function PartnerLogo() {
  const data = useStaticQuery(graphql`
    query MyQuery20 {
      allContentfulPartnerLogos {
        edges {
          node {
            bigCommerceLogo {
              image {
                file {
                  url
                }
              }
              altText
            }
            eConsultancyLogo {
              image {
                file {
                  url
                }
              }
              altText
            }
            idmLogo {
              image {
                file {
                  url
                }
              }
              altText
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  const {
    bigCommerceLogo,
    eConsultancyLogo,
    idmLogo
  } = data.allContentfulPartnerLogos.edges[0].node;

  return (
    <div className="PartnerLogos">
      <p className="PartnerTitle" data-aos="flip-up">My Professional Certificates</p>
      <div className="PartnerLogosRow2">
        <img
          className={"EConsultancyLogo"}
          src={eConsultancyLogo.image.file.url}
          alt={eConsultancyLogo.altText}
          data-aos="flip-left"
          
        />
        <img
          className={"IDMLogo"}
          src={idmLogo.image.file.url}
          alt={idmLogo.altText}
          data-aos="flip-up"
        />
        <img
          className={"BigCommerceLogo"}
          src={bigCommerceLogo.image.file.url}
          alt={bigCommerceLogo.altText}
          data-aos="flip-right"
        />
      </div>
    </div>
  );
}
