import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import '../scss/ValuePropAbout.scss';

export default function ValuePropAbout() {
  const data = useStaticQuery(graphql`
  query MyQuery1000 {
    allContentfulValuePropositionBar(filter:{id:{eq:"b43b077c-cbda-5385-8b64-2ba1dff7d4d7"}}) {
      edges {
        node {
          bodyText
          bodyText2
          bodyText3
          title
          title2
          title3
          image3 {
            altText
            image {
              file {
                url
              }
            }
          }
          image2 {
            altText
            image {
              file {
                url
              }
            }
          }
          image {
            altText
            image {
              file {
                url
              }
            }
          }
          id
          outsideTitle
        }
      }
    }
  }
  `);

  const {
    title,
    title2,
    title3,
    bodyText,
    bodyText2,
    bodyText3,
    image,
    image2,
    image3,
  } = data.allContentfulValuePropositionBar.edges[0].node;

  return (
    <div className="valuePropAboutDisplayBar">
      <div className="vpabar">
        <div className="vpaitems">
          <div className="vpaitem">
            <img className="vpaimage" src={image.image.file.url} alt={image.altText} />
            {/* <div className="vpacontent">
              <div className="vpatitle">
                <span className="larger-texta"><strong>18</strong></span> years
              </div>
              <div className="vpasentence">{bodyText}</div>
            </div> */}
          </div>
          <div className="vpaitem">
            <img className="vpaimage2" src={image2.image.file.url} alt={image2.altText} />
            {/* <div className="vpacontent2">
              <div className="vpatitle2"><strong>{title2}</strong></div>
              <div className="vpasentence2">{bodyText2}</div>
            </div> */}
          </div>
          <div className="vpaitem">
            <img className="vpaimage3" src={image3.image.file.url} alt={image3.altText} />
            {/* <div className="vpacontent3">
              <div className="vpatitle3"><strong>{title3}</strong></div>
              <div className="vpasentence3">{bodyText3}</div>
            </div> */}
          </div>


          <div className="vpaitemsMQ">
          <div className="vpaitemMQ">
            <img className="vpaimageMQ" src={image.image.file.url} alt={image.altText} />
          </div>
          <div className="vpaitemMQ">
            <img className="vpaimage2MQ" src={image2.image.file.url} alt={image2.altText} />
          </div>
          <div className="vpaitemMQ">
            <img className="vpaimage3MQ" src={image3.image.file.url} alt={image3.altText} />
          </div>
        </div>





        </div>
      </div>
    </div>
  );
}
