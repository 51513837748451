import React, { useEffect } from "react";
import "../scss/AboutUsPagePic2.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { StaticImage } from "gatsby-plugin-image"

export default function AboutUsPagePic2() {
  const data = useStaticQuery(graphql`
    query MyQuery25 {
      allContentfulImageBlock(filter: { name: { eq: "Yemi in Suit" } }) {
        edges {
          node {
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000, offset: -60 });
  }, []);

  if (data.allContentfulImageBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  return (
    <div className="contentFeature8">
      <div data-aos="fade-up" className="boardTextContainer8 fade-in">
        <div className={"displayBoardContainer8"}>
          <div className="displayBoard8"></div>
        </div>
      </div>

      <div className={"textContainer8"}>
        <p className={"title8"}>
          {data.allContentfulImageBlock.edges[0].node.name}
        </p>
      </div>

      <div className={"imageContainer8"}>
        {/* <img
          data-aos="fade-up"
          className={"bigImage"}
          alt={""}
          key={""}
          src={data.allContentfulImageBlock.edges[0].node.image.image.file.url}
        /> */}
        <StaticImage data-aos="fade-up" className={"bigImage"} src="https://images.ctfassets.net/x5jqyuhgi4rx/3ZGJF4QUkD0qih4onw5Xmk/2c56dd04ab078b59f56dd72cd390deb2/yemi_hand_drawn_fk.webp" layout="fullWidth" alt=""/>
      </div>
    </div>
  );
}
