import * as React from "react"

import Layout from "../components/Layout"
import HeroImageAbout from "../components/AboutPage/HeroImageAbout"
import PartnerLogo from "../components/AboutPage/partner"
import AboutUsPageText1 from "../components/AboutPage/AboutUsPageText1"
import AboutUsPageText2 from "../components/AboutPage/AboutUsPageText2"
import AboutUsPageText4 from "../components/AboutPage/AboutUsPageText4"
import AboutUsPageText5 from "../components/AboutPage/AboutUsPageText5"
import AboutUsPageText3 from "../components/AboutPage/AboutUsPageText3"
import AboutUsPageQuote from "../components/AboutPage/AboutUsPageQuote"
import AboutUsPagePic1 from "../components/AboutPage/AboutUsPagePic1"
import AboutUsPagePic2 from "../components/AboutPage/AboutUsPagePic2"
import AboutUsPagePic3 from "../components/AboutPage/AboutUsPagePic3"
import AboutUsPicture5 from "../components/AboutPage/AboutUsPicture5"
import AboutUsPageFlexBox from "../components/AboutPage/AboutUsPageFlexBox"
import ValuePropAbout from "../components/AboutPage/ValuePropAbout"
import WhatIDoBarAbout from "../components/AboutPage/WhatIDoBarAbout"
import PracticeBar from "../components/AboutPage/PracticeBar"


function aboutUsPage({ data }) {
  return (
    <Layout>
      <HeroImageAbout />
      <ValuePropAbout />
      <PartnerLogo />
      <AboutUsPageText1 />
      <AboutUsPagePic2 />
      <AboutUsPageText2 />
      <AboutUsPagePic3 />
      <AboutUsPageText4 />
      <AboutUsPicture5 />
      <AboutUsPageText5 />
      <WhatIDoBarAbout />
      <AboutUsPagePic1 />
      <AboutUsPageText3 />
      <AboutUsPageFlexBox />
    </Layout>
  )
}

export default aboutUsPage

// function AboutUsPage({ data }) {
//   return (
//     <Layout>
//       <div style={containerStyle}>
//         <HeroImageAbout />
//         <div style={spacerStyle} /> {/* Add a spacer element */}
//         <PartnerLogo />
//         <div style={spacerStyle} /> {/* Add a spacer element */}
//         <AboutUsPageText1 />
//         <div style={spacerStyle} /> {/* Add a spacer element */}
//         <AboutUsPagePic1 />
//         <div style={spacerStyle} /> {/* Add a spacer element */}
//         <AboutUsPageText2 />
//         <div style={spacerStyle} /> {/* Add a spacer element */}
//         <AboutUsPageQuote />
//         <div style={spacerStyle} /> {/* Add a spacer element */}
//         <AboutUsPagePic2 />
//         <div style={spacerStyle} /> {/* Add a spacer element */}
//         <AboutUsPageText3 />
//         <div style={spacerStyle} /> {/* Add a spacer element */}
//         <AboutUsPageFlexBox />
//       </div>
//     </Layout>
//   );
// }

// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
// };

// const spacerStyle = {
//   marginBottom: '700px', /* Adjust the spacing as per your requirements */
// };

// export default AboutUsPage;
