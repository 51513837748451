import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import "../scss/AboutUsPagePic1.scss"; // Make sure to adjust the path to your SCSS file

export default function AboutUsPagePic1() {
  const data = useStaticQuery(graphql`
    query MyQuery22 {
      allContentfulFlexBoxStyle(filter: { title: { eq: "My Heritage" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            callToAction
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            subTitle
            title
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000, offset: -60 });
  }, []);

  const backgroundImageUrl = data.allContentfulFlexBoxStyle.edges[0].node.image.image.file.url;

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (data.allContentfulFlexBoxStyle.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  const { bodyText, title } = data.allContentfulFlexBoxStyle.edges[0].node

  return (
    <div className={isMobileView ? "normalImageContainer" : "parallaxContainer"}>
      <p className="title7">{documentToReactComponents(JSON.parse(bodyText.raw))}</p>
      {isMobileView ? (
        <img src={backgroundImageUrl} alt={data.allContentfulFlexBoxStyle.edges[0].node.image.altText} />
      ) : (
        <div className="parallax" style={{ backgroundImage: `url(${backgroundImageUrl})` }} />
      )}
    </div>
  );
}


//https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_parallax









